import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ProjectDetails2Header from "components/Project-details2-header/project-details2-header";
import ProjectIntroductionRunway from "components/Project-introduction/project-introduction-runway";
import ProjectGalleryRunway from "components/Project-gallery/project-gallery-runway";
import ProjectDescription from "components/Project-description/project-description";
import ProjectVideo1 from "components/Project-video/project-video-runway1";
import ProjectVideo2 from "components/Project-video/project-video-runway2";
import ProjectVideo3 from "components/Project-video/project-video-runway3";
import ProjectVideo4 from "components/Project-video/project-video-runway4";
import ProjectVideo5 from "components/Project-video/project-video-runway5";
import ProjectVideo6 from "components/Project-video/project-video-runway6";
import ProjectDate from "data/runway-details.json";
import DarkTheme from "layouts/Dark";

const RunwaySimulator = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <div className="wrapper">
        <ProjectDetails2Header projectHeaderData={ProjectDate} />
        <ProjectIntroductionRunway projectIntroductionData={ProjectDate.intro} />
        <ProjectGalleryRunway />
        <ProjectDescription projectDescriptionData={ProjectDate.description} />
        <ProjectVideo1 projectVideoDate={ProjectDate} />
        <ProjectVideo2 projectVideoDate={ProjectDate} />
        <ProjectVideo3 projectVideoDate={ProjectDate} />
        <ProjectVideo4 projectVideoDate={ProjectDate} />
        <ProjectVideo5 projectVideoDate={ProjectDate} />
        <ProjectVideo6 projectVideoDate={ProjectDate} />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Runway Simulator</title>
    </>
  )
}

export default RunwaySimulator;
